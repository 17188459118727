<template>
  <div>
    <!--      头部      -->
    <bread></bread>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-button type="primary" size="small" @click="setDepartmentBtn()" plain>新增业务组</el-button>
                <!-- <el-button type="warning" size="small" class="el-icon-download" @click="downloadTemplate">下载模板</el-button> -->
              </el-form-item>
              <!-- <el-form-item>
                <el-upload class="upload" :show-file-list="false" accept=".xlsx,.xls" :auto-upload="true" :headers="headers" :action="fileUploadUrl" :on-success="uploadSuccess">
                  <el-button size="small" type="success" class="el-icon-upload2">批量导入</el-button>
                </el-upload>
              </el-form-item> -->
            </el-form>
          </div>
        </div>
        <el-table :data="HospitalDepartmentData" stripe style="width: 100%" row-key="id" :tree-props="{ children: 'childrens', hasChildren: 'childrens.length>0' }" :header-cell-style="{ background: '#F5F7FC' }">
          <el-table-column prop="name" label="业务组名称"> </el-table-column>
          <el-table-column prop="code" label="业务组编号" align="center"> </el-table-column>
          <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">
              <span class="info optionBtn" @click="EditDepartmentBtn(scope.row)">编辑</span>
              <span
                class="primary optionBtn"
                @click="
                  setDepartmentDialog = true
                  DepartmentFrom.parentName = scope.row.name
                  DepartmentFrom.parentId = scope.row.id
                "
                >添加子类</span
              >
              <span class="danger optionBtn" @click="RemoveDepartment(scope.row)">删除 </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--    Dialog 新增/编辑    -->
    <el-dialog :title="DialogTitle" :visible.sync="setDepartmentDialog" width="500px">
      <el-form class="dialog-form" :model="DepartmentFrom" ref="DepartmentFrom" :rules="rules" :label-width="formLabelWidth">
        <el-form-item label="业务组名称" prop="name">
          <el-input v-model="DepartmentFrom.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="业务组编号" prop="code">
          <el-input v-model="DepartmentFrom.code" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="业务组全称" prop="fullName">
          <el-input v-model="DepartmentFrom.fullName" autocomplete="off"></el-input>
        </el-form-item>
        < -->
        <!-- <el-form-item label="上级业务组" v-show="DepartmentFrom.parentName">
                    <div v-text="DepartmentFrom.parentName"></div>

                </el-form-item> -->
        <el-form-item label="上级业务组" prop="value">
          <el-cascader v-model="DepartmentFrom.parentId" :options="DepartmentDataTree" :props="{ checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }" :clearable="true" style="width: 100%" filterable @change="handleOption" :disabled="DepartmentFrom.id > 0"></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseDiglog('AddDepartmentInfo')">取 消</el-button>
        <el-button type="primary" @click="saveDepartmentBtn('DepartmentFrom')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Organization } from '../../../components/HospitalDomain/Organization'
import { Department } from '../../../components/HospitalDomain/Department'
import bread from '../../../components/common/breadcrumb'
export default {
  inject: ['reload'],
  name: 'department',
  components: {
    bread,
  },
  computed: {
    headers() {
      return {
        Authorization: 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token,
      }
    },
  },
  data() {
    var organization = new Organization(this.TokenClient, this.Services.Authorization)
    var department = new Department(this.TokenClient, this.Services.Authorization)
    return {
      Organization: organization,
      Department: department,
      tableData: [],
      DepartmentData: [],
      setDepartmentDialog: false,
      organizationId: null,
      DialogTitle: '新增业务组',
      DepartmentFrom: {},
      searchForm: {},
      formLabelWidth: '120px',
      HospitalDepartmentData: [],
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      value: [],
      parentId: null,
      DepartmentDataTree: [],
      rules: {
        name: [{ required: true, message: '请输入业务组名称', trigger: 'blur' }],
        fullName: [{ required: true, message: '请输入业务组全称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入业务组编号', trigger: 'blur' }],
      },
      fileUploadUrl: `${this.Services.Authorization}/api/File/upload`,
    }
  },
  mounted() {
    this.LoadDepartment()
    // this.InitialDepartment();
  },
  methods: {
    downloadTemplate() {
      let a = document.createElement('a')
      a.href = './Department.xlsx'
      a.download = '业务组模版.xlsx'
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    uploadSuccess(res, file) {
      var _this = this
      let filePath = `${res[0].completeFilePath}`.trim()
      this.Department.ImportOrganizationData(
        filePath,
        function (data) {
          _this.$message({
            type: 'success',
            message: '操作成功!',
          })
          _this.LoadDepartment()
        },
        function (err) {
          let jsonObj = JSON.parse(err.msg)
          // _this.$message({
          //   message: jsonObj.msg,
          //   type: 'error',
          // })
          _this.$alert(jsonObj.msg, '提示', {
            confirmButtonText: '确定',
            callback: (action) => {},
          })
        }
      )
    },
    handleOption(e) {
      this.DepartmentFrom.parentId = e[e.length - 1]
    },
    InitialDepartment() {
      var _this = this
      _this.Department.OrgDepartment(
        this.userInfo.organizationId,
        function (data) {
          _this.DepartmentData = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    buildTree(data, parentId) {
      return data.reduce((tree, node) => {
        if (node.pid === parentId) {
          const children = buildTree(data, node.id)
          if (children.length > 0) {
            node.children = children
          }
          tree.push(node)
        }
        return tree
      }, [])
    },
    LoadDepartment() {
      let organizationId = JSON.parse(sessionStorage.getItem('userInfo')).organizationId
      var _this = this
      _this.Organization.DepartmentTree(
        organizationId,
        true,
        function (data) {
          _this.HospitalDepartmentData = data.data
          _this.DepartmentDataTree = data.data
          // const result = _this.buildTree(data.data, null)
          // console.log(result) // 打印处理后的树形数据
        },
        function (err) {
          console.log(err)
          _this.HospitalDepartmentData = []
          _this.DepartmentDataTree = []
        }
      )
    },
    setDepartmentBtn() {
      var _this = this
      _this.parentId = null
      _this.DepartmentFrom = {
        code: null,
        fullName: null,
        name: null,
        organizationId: _this.userInfo.organizationId,
        parentId: null,
        isBusiness: true,
      }
      _this.setDepartmentDialog = true
      _this.DialogTitle = '新增业务组'
    },
    EditDepartmentBtn(item) {
      var _this = this
      _this.parentId = null
      _this.DepartmentFrom = {
        id: item.id,
        code: item.code,
        fullName: item.fullName,
        name: item.name,
        organizationId: item.organizationId,
        parentId: item.parentId,
        isBusiness: true,
      }
      _this.setDepartmentDialog = true
      _this.DialogTitle = '编辑业务组'
    },
    saveDepartmentBtn(formName) {
      var _this = this
      // if (_this.parentId != "") {
      //   _this.DepartmentFrom.parentId = _this.parentId;
      // }
      this.DepartmentFrom.isBusiness = true
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          if (_this.DepartmentFrom.id) {
            _this.Department.EditDepartment(
              _this.DepartmentFrom,
              function (data) {
                _this.$message({
                  type: 'success',
                  message: '操作成功!',
                })
                _this.reload()
              },
              function (err) {
                let jsonObj = JSON.parse(err.msg)
                _this.$message({
                  type: 'error',
                  message: jsonObj.msg,
                })
              }
            )
          } else {
            _this.Department.AddDepartment(
              _this.DepartmentFrom,
              function (data) {
                _this.$message({
                  type: 'success',
                  message: '添加成功!',
                })
                _this.reload()
              },
              function (err) {
                let jsonObj = JSON.parse(err.msg)
                _this.$message({
                  type: 'error',
                  message: jsonObj.msg,
                })
              }
            )
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    RemoveDepartment(e) {
      var _this = this
      this.$confirm('是否删除此信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: 'warning'
      })
        .then(() => {
          _this.Department.RemoveDepartment(
            e.id,
            function (data) {
              _this.$message({
                type: 'success',
                message: '删除成功!',
              })
              _this.reload()
            },
            function (err) {
              let jsonObj = JSON.parse(err.msg)
              _this.$message({
                type: 'error',
                message: jsonObj.msg,
              })
            }
          )
        })
        .catch(() => {})
    },
    CloseDiglog() {
      var _this = this
      _this.setDepartmentDialog = false
      _this.DepartmentFrom = {}
    },
  },
}
</script>

<style scoped>
.dialog-form {
  width: 430px;
}
.table-top {
  margin-bottom: 15px;
}
</style>
